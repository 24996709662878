<template>
    <div class="row mb-4 ms-5">
        <div class="col-md-6">
            <p class="lead">{{ jobTitle }}</p>
        </div>
        <div class="col-md-3">
            <p class="lead">{{ jobLocation }}</p>
        </div>
        <div class="col-md-3">
            <button class="btn btn-danger" @click="toggleShowMore">{{ buttonText }}</button>
        </div>
    </div>
    <div class="container" v-if="showMore">
        <p v-if="isSuperintendant">
            The MMS Group currently have vacancies for Building Superintendants to join our team in Toronto.
            The Superintendant works under the direction of the Property Manager to oversee the administrative
            and maintenance operations of a building.
        </p>
        <p v-if="isPlumber">
            The MMS Group currently have vacancies for Licensed Plumbers to join our team in Toronto who 
            can perform emergency service on plumbing and heating systems in high-rise residential apartment
            buildings and commercial properties throughout the GTA.
        </p>
        <p>
            This job description is not intended and should not be construed to be an exhaustive list of all 
            responsibilities, or working conditions associated with the job. It is intended to provide a 
            description of those principal job elements.
        </p>
        <p class="fw-bold">The key responsibilities of a {{ jobTitle }} include but are not limited to:</p>
        <p>
            <ul>
                <li v-for="responsibility in jobResponsibilities" :key="responsibility">{{ responsibility }}</li>
            </ul>
        </p>
        <p class="fw-bold">
            To succeed in this role, the candidate must possess the following combination of education,
            experience, and skills:
        </p>
        <p>
            <ul>
                <li v-for="requirement in jobRequirements" :key="requirement" >{{ requirement }}</li>
            </ul>
        </p>
        <p class="fw-bold" v-if="jobAttributes">
            Attributes:
        </p>
        <p v-if="jobAttributes" >
            <ul>
                <li v-for="attribute in jobAttributes" :key="attribute">{{ attribute }}</li>
            </ul>
        </p>
        <p class="fw-bold" v-if="workingConditions">
            Working Conditions:
        </p>
        <p v-if="workingConditions" >
            <ul>
                <li v-for="condition in workingConditions" :key="condition">{{ condition }}</li>
            </ul>
        </p>
        <p v-if="jobPermanent">
            This is a full-time, permanent position with a salary based on experience and qualifications, 
            an apartment is provided, and a generous benefits package.
        </p>
        <p class="fw-bold">Candidates must be authorized to work in Canada.</p>
    </div>
    <hr class="mt-4">
</template>

<script>
export default {
    props: ['jobTitle', 'jobLocation', 'jobResponsibilities', 'jobRequirements', 'jobAttributes', 'workingConditions', 'jobPermanent'],
    data(){
        return {
            showMore: false
        };
    },
    methods: {
        toggleShowMore(){
            this.showMore = !this.showMore;
        }
    },
    computed: {
        buttonText(){
            if(this.showMore){
                return 'Show Less';
            }else{
                return 'Show More';
            }
        },
        isSuperintendant(){
            return this.jobTitle === 'Building Superintendant (Living in/Living out)';
        },
        isPlumber(){
            return this.jobTitle === 'Plumber';
        }
    }
}
</script>