<template>
        <div>
            <ul class="list">
                <li class="py-2" v-for="link in navLinks" :key="link.label" >
                    <router-link class="nav-link active" aria-current="page" :to="link.link">{{ link.label }}</router-link>
                </li>
            </ul>
        </div>
</template>

<script>
export default {
    props: ['navLinks'],
    emits: ['close-menu'],
    methods: {
        closeMenu(){
            this.$emit('close-menu');
        }
    }
}
</script>

<style scoped>
li {
    width: 100%;
    list-style: none;
    color: #CC0001;
    background: white;
}

li:hover,
.router-link-exact-active {
    color: black;
}

.mobileNav-enter-from {
    opacity: 0;
    transform: translateY(-30px);
}
.mobileNav-enter-active {
    transition: all 0.3s ease-out;
}
.mobileNav-enter-to,
.mobileNav-leave-from {
    opacity: 1;
    transform: translateY(0);
}
.mobileNav-leave-active {
    transition: all 0.3s ease-in;
}
.mobileNav-leave-to {
    opacity: 0;
    transform: translateY(30px);
} 
</style>