<template>
    <div class="my-5 bg-dark px-3 rounded">
        <h3 class="py-4 text-white">{{ clientName }}</h3>
        <div class="row justify-content-center">
            <div class="col-12 col-md-4 mb-5 tilt-box-wrap" v-for="image in clientImages" :key="image">
                <span class="t_over" ></span>
                <span class="t_over" ></span>
                <span class="t_over" ></span>
                <span class="t_over" ></span>
                <span class="t_over" ></span>
                <span class="t_over" ></span>
                <span class="t_over" ></span>
                <span class="t_over" ></span>
                <span class="t_over" ></span>
                <div class="tilt-box">
                    <img :src="require(`../../assets/clients/${image}`)" :alt="clientName" class="responsive img-thumbnail">
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    props: ['clientName', 'clientImages']
}
</script>

<style scoped>
.tilt-box-wrap {
    position: relative;
    transition: all 0.6s ease-out;
    perspective: 1000px;
}

.tilt-box-wrap:hover {
    transition: all 0.3s linear;
    transform: scale(1.1);
}

.tilt-box {
    width: 100%;
    height: 100%;
    position: relative;
    transition: all 0.6s ease-out;
    transform: rotateX(0deg) rotateY(0deg);
    perspective: 1000px;
    transform-style: preserve-3d;
}

.t_over {
    width: 33.333%;
    height: 33.333%;
    position: absolute;
    z-index: 1;
}

.t_over:nth-child(1) { left: 0; top: 0; }
.t_over:nth-child(2) { left: 33.333%; top: 0; }
.t_over:nth-child(3) { left: 66.666%; top: 0; }
.t_over:nth-child(4) { left: 0; top: 33.333%; }
.t_over:nth-child(5) { left: 33.333%; top: 33.333%; }
.t_over:nth-child(6) { left: 66.666%; top: 33.333%; }
.t_over:nth-child(7) { left: 0; top: 66.666%; }
.t_over:nth-child(8) { left: 33.333%; top: 66.666%; }
.t_over:nth-child(9) { left: 66.666%; top: 66.666%; }

.t_over:nth-child(1):hover ~ .tilt-box { transform: rotateX(-20deg) rotateY(20deg); }
.t_over:nth-child(2):hover ~ .tilt-box { transform: rotateX(-20deg) rotateY(0deg); }
.t_over:nth-child(3):hover ~ .tilt-box { transform: rotateX(-20deg) rotateY(-20deg); }
.t_over:nth-child(4):hover ~ .tilt-box { transform: rotateX(0deg) rotateY(20deg); }
.t_over:nth-child(5):hover ~ .tilt-box { transform: rotateX(0deg) rotateY(0deg); }
.t_over:nth-child(6):hover ~ .tilt-box { transform: rotateX(0deg) rotateY(-20deg); }
.t_over:nth-child(7):hover ~ .tilt-box { transform: rotateX(20deg) rotateY(20deg); }
.t_over:nth-child(8):hover ~ .tilt-box { transform: rotateX(20deg) rotateY(0deg); }
.t_over:nth-child(9):hover ~ .tilt-box { transform: rotateX(20deg) rotateY(-20deg); }

.responsive {
    width: 100%;
    max-height: 450px;
}
</style>