<template>
    <section class="container-fluid pt-2 bg-light text-center">
        <h1 class="my-5">CONTACT US</h1>
        <div class="row justify-content-center">
            <contact-container v-for="contact in contactList" :key="contact.name" 
            :contact-name="contact.name"
            :contact-title="contact.title"
            :contact-email="contact.email"
            :contact-phone="contact.phone"
            ></contact-container>
        </div>
    </section>
</template>

<script>
import ContactContainer from '../components/contact/ContactContainer.vue';
export default {
    components: { ContactContainer },
    data(){
        return {
            contactList: [
                {
                    name: 'General Enquiry',
                    title: null,
                    email: 'admin@marissamsinc.com',
                    phone: '+1 905-302-2888'
                },
                {
                    name: 'Lalith Ranasinghe - BA, RWP, RSP',
                    title: 'Managing Director',
                    email: 'lalith.r@marissamsinc.com',
                    phone: '+1 647-309-8065'
                },
                // {
                //     name: 'Dan Mara - MBA',
                //     title: 'Director, Operations',
                //     email: 'dan@marissamsinc.com',
                //     phone: '+1 647-618-1584'
                // }
            ]
        };
    }
}
</script>

<style scoped>
/* .content {
    padding-top: 5%;
}

@media (max-width: 768px){
    .content {
        padding-top: 35%;
    }
} */

.contact {
    height: 100vh;
    background-image: url("../assets/meeting.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

/* h1 {
    color: #C00001; 
} */

input,
input:focus,
textarea,
textarea:focus {
    background: rgba(0, 0, 0, 0.2);
    color: white
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
}
</style>