<template>
        <footer class="pt-4">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-3 float-start">
                        <div>
                            <ul class="ps-0">
                                <li>
                                    <router-link class="footerLink" to="/">Home</router-link>
                                </li>
                                <li>
                                    <router-link class="footerLink" to="/privacyPolicy">Privacy Policy</router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="float-end" v-if="mobileView">
                            <ul class="ps-0">
                                <li>
                                    <a href="https://www.instagram.com/mms_gta_on/" target="_blank">
                                        <img src="../../assets/Instagram_logo_2016.svg.png" alt="instragram" class="socials">
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/MMS_GTA_ON" target="_blank">
                                        <img src="../../assets/Twitter-logo.svg.png" alt="twitter" class="socials">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <!-- <ul class="ps-0">
                            <li>
                                <img src="../../assets/ccitoronto.jpg" alt="ccitoronto" class="credentials mb-4">
                            </li>
                            <li class="">
                                <img src="../../assets/acmo.png" alt="acmo" class="credentials">
                            </li>
                            <li>
                                <img src="../../assets/cci-alberta.jpg" alt="ccitoronto" class="credentials mb-4">
                            </li>
                        </ul> -->
                        <img src="../../assets/signature_fotter_2.jpg" id="credentials-images" alt="">
                    </div>
                    <div class="col-12 col-md-3 float-end" v-if="!mobileView" >
                        <ul class="ps-0">
                            <li>
                                <a href="https://www.instagram.com/mms_gta_on/" target="_blank">
                                    <img src="../../assets/Instagram_logo_2016.svg.png" alt="instragram" class="socials">
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/MMS_GTA_ON" target="_blank">
                                    <img src="../../assets/Twitter-logo.svg.png" alt="twitter" class="socials">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <p class="pt-3" id="copyright">&copy; 2023 Marissa Management &amp; Services Inc. - Website built by Shermal Mara</p>
            </div>
        </footer>
</template>

<script>
export default {
    props: ['mobileView']
}
</script>

<style scoped>
footer {
    background: #fff;
    color: black;
}

li {
    list-style: none;
    float: left;
    padding: 0 10px;
}

.footerLink {
    text-decoration: none;
    color: black;
}

.footerLink:hover,
.footerLink:active {
    color: #c0c0c0;
}

.socials {
    height: 30px;
    margin-bottom: 5px;
}

.socials:hover,
.socials:active {
    height: 35px;
}

.credentials {
    height: 60px;
}

#copyright {
    font-size: 0.6rem;
}

@media (max-width: 768px){
    .credentials {
        height: 36px;
        margin-top: 10px;
    }

    .socials,
    .socials:active {
        height: 26px;
    }

    .socials:hover {
        height: 28px;
    }

    #credentials-images {
        width: 300px;
    }
}
</style>