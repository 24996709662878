<template>
    <button @click="backToTop"><font-awesome-icon icon="fa-solid fa-angles-up" /></button>
</template>

<script>
export default {
    data(){
        return {
            button: null
        };
    },
    methods: {
        scrollFunction(){
            if(window.pageYOffset > 0){
                if(!this.button.classList.contains("btnEntrance")){
                    this.button.classList.remove("btnExit");
                    this.button.classList.add("btnEntrance");
                    this.button.style.display = "block";
                }
            } else{
                if(!this.button.classList.contains("btnExit")){
                    this.button.classList.remove("btnEntrance");
                    this.button.classList.add("btnExit");
                    setTimeout(function(){
                        this.button.style.display = "none";
                    }, 250);
                }
            }
        },
        backToTop(){
            window.scrollTo(0,0);
        }
    },
    mounted(){    
        this.button = document.querySelector("button");
        window.addEventListener("scroll", this.scrollFunction);
    }
}
</script>

<style scoped>
button {
    display: none;
    position: fixed;
    z-index: 999;
    bottom: 20px;
    right: 20px;
    font-size: 26px;
    width: 50px;
    height: 50px;
    color: #333;
    cursor: pointer;
    outline: none;
    border: 3px solid #333;
    border-radius: 50%;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-property: background-color, color;
}

button:hover {
    background-color: #333;
    color: #fff;
}

.btnEntrance{
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: btnEntrance;
}

/* Zoom In */
@keyframes btnEntrance{
    from{
        opacity: 0;
        transform: scale3d(0.3,0.3,0.3);
    }
    to{
        opacity: 1;
    }
}

.btnExit{
    animation-duration: 0.25s;
    animation-fill-mode: both;
    animation-name: btnExit;
}


/* Zoom Out */
@keyframes btnExit{
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
        transform: scale3d(0.3,0.3,0.3);
    }
}


/* Adjusting the button for smaller device sizes */
@media(max-width: 992px){
    #back-to-top-button{
        font-size: 22px;
        width: 40px;
        height: 40px;
    }
}

@media(max-width: 768px){
    #back-to-top-button{
        font-size: 18px;
        width: 32px;
        height: 32px;
    }
}
</style>