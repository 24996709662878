<template>
    <section class="container-fluid jumbotron">
        <div class="col-md-6 content">
            <div class="h-100 p-5 text-white bg-dark rounded-3">
                <h2 class="mb-4">About Us</h2>
                <p>
                    As a Company, we believe in building long lasting client partnerships which help us all flourish
                    within our businesses. We take pride in our accomplishments and we are innovative and value
                    driven company with an excellent track record for business customer satisfaction.
                </p>
                <p>
                    We are committed to dedicating our credibility by being transparent and upholding our core values.
                    We have a proven record of Client satisfaction by sharing our diversity and respect. Putting a 
                    smile on our customer's faces is everything to us. Consider us for all your building solution needs
                    and make MMS your one stop shop.
                </p>
                <p>
                    Now we are in your province and MMS hotline +1 905-302-2888 is personally answered 24/7 with an
                    immediate response.
                </p>
                <!-- <p>
                    Marissa Management &amp; Services Inc. (MMS) is a group of companies providing
                    building solutions to the Greater Toronto Area (GTA). MMS is keen and
                    delighted to provide a quality service to our Clients and Partners while maintaining the
                    customer satisfaction and industry standards.
                </p>
                <p>
                    We are committed to maintain Health and Safety at all our work places. Continuous
                    staff trainings and awareness process is in place within MMS which will ensure quality
                    work delivered safely while maintaining the highest level of service excellence.
                </p>
                <p>
                    MMS will take measures to ensure that your Properties are maintained in a clean and
                    presentable condition at all times. We conduct a quality survey enabling us to evaluate
                    our own services periodically. This will assist us to measure our performance and
                    improve if/where while making sure Customer satisfaction is maintained at all times.
                </p> -->
            </div>
        </div>
    </section>
</template>

<style scoped>
.jumbotron {
    height: 110vh;
    background-image: url("../../assets/aboutUs/building-bw.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 5%;
}

@media (max-width: 768px){
    .jumbotron {
        padding-top: 10%;
    }
}
</style>