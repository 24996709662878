<template>
    <section class="bg-light py-5">
        <div class="container text-center justify-content-center">
            <h1>OUR CLIENTS</h1>
            <client-container v-for="client in clients" :key="client.name" 
                :client-name="client.name"
                :client-images="client.images"
            ></client-container>
        </div>
    </section>
</template>

<script>
import ClientContainer from '../components/clients/clientContainer.vue';
export default {
    components: { ClientContainer },
    data(){
        return {
            clients: [
                {
                    name: '297 OAK WALK DRIVE',
                    images: [
                        'client1-1.jpeg',
                        'client1-2.jpeg',
                    ]
                },
                {
                    name: '2485 TAUNTON ROAD',
                    images: [
                        'client2-1.jpeg',
                        'client2-2.jpeg',
                    ]
                },
                {
                    name: 'IMPERIAL PLAZA',
                    images: [
                        'client3-1.jpg',
                        'client3-2.jpg',
                        'client3-3.jpg',
                    ]
                },
                {
                    name: 'AURA CONDO',
                    images: [
                        'client4-1.jpeg',
                        'client4-2.jpg',
                        'client4-3.jpg'
                    ]
                },
                {
                    name: 'METROPLACE - 55 DE BOERS DRIVE',
                    images: [
                        'client6-1.jpeg',
                        'client6-3.jpeg',
                        'client6-2.jpeg'
                    ]
                },
                {
                    name: '1070 SHEPPARD AVE. W',
                    images: [
                        'client5-1.jpeg',
                        'client5-2.jpeg'
                    ]
                },
                {
                    name: '80 Cumberland St, Toronto, ON M5R 3N7',
                    images: [
                        'client7-1.jpg',
                        'client7-2.jpg'
                    ]
                },
                {
                    name: '88 - Minto Yorkville Park - 88 Cumberland St, Toronto',
                    images: [
                        'client8-1.jpg',
                        'client8-2.png'
                    ]
                },
            ]
        };
    }
}
</script>

<style scoped>
/* h1 {
    padding-top: 5%;
}

@media (max-width: 768px){
    h1 {
        padding-top: 10%;
    }
} */
</style>