<template>
<div>
    <services-jumbotron></services-jumbotron>
    <section class="container-fluid py-5 bg-light">
        <div class="row justify-content-center">
            <services-container v-for="service in services" :key="service.title" 
                :service-title="service.title"
                :service-text="service.text"
                :service-text2="service.text2"
                :list-items="service.listItems"
            ></services-container>
        </div>
    </section>
</div>
</template>

<script>
import ServicesJumbotron from '../components/services/ServicesJumbotron.vue';
import ServicesContainer from '../components/services/ServicesContainer.vue';
export default {
    components: { ServicesJumbotron, ServicesContainer },
    data(){
        return {
            services: [
                {
                    title: 'General Contracting',
                    text: 'At MMS, our mission is to deliver to all of our clients a finished project that is “above and beyond” their expectations. We do not compromise on our standards of quality, both in materials and workmanship. Coupled with integrity and a fully backed guarantee, MMS is fully licensed and insured.',
                    text2: null,
                    list: null
                },
                {
                    title: 'Building Superintendent Services',
                    text: 'The Superintendent Service is important to the successful operation of a multi-residential portfolio. It is responsible for the physical maintenance and safety of the building and surrounding property and its occupants. Building Superintendent Services MMS offer will have a service-oriented approach with safety first attitude.',
                    text2: null,
                    list: null
                },
                {
                    title: 'Disaster Restoration',
                    text: 'Marissa Management and Services Inc. is a group of companies who got teams of property restoration experts and immediate responders who are ready 24/7 to help building/homeowners when disaster strikes. Our professionals are amongst the most trained and experienced repairmen and technicians in the industry.',
                    text2: null,
                    listItems: ['Water Leak and Flood Damage', 'Mold Removal', 'Fire and Smoke Damage', 'Asbestos Removal', 'Vandalism and Break-Ins','Wind and Hail Damage']
                },
                {
                    title: 'HVAC / Plumbing / Electrical Services',
                    text: 'MMS has a licensed team of skilled, reliable and trustworthy HVAC/Plumbing/Electrical Service. With our experience, we have cultivated a strong business by knowing their customers and needs. We are dedicated to provide their customers with the highest quality preventive maintenance service and installation work with our experts.',
                    text2: null,
                    listItems: null
                },
                {
                    title: 'Property Maintenance / Janitorial Services',
                    text: 'A beautiful, clean and technically sound property will attract customers to your business and help residents take pride in where they live and work. The condition of your commercial or residential property is how your business presents itself and represents your brand. You want to ensure that your property is safe, in full working order and beautiful all year round.',
                    text2: 'MMS team manages a variety of Residential properties in the GTA and well equipped to fulfil your expectations 24/7.',
                    listItems: ['Clearing Services', 'Carpet Cleaning', 'Garage Power Wash', 'Garbage Chute Cleaning', 'Landscaping and Snow Removal']
                },
                {
                    title: 'Security Management',
                    text: 'We recognize our clients needs for a reliable partner to manage all their Security requirements. Our Security Officers are carefully selected, well trained and equipped, have the skills, experience and confidence to work in any challenging environment. They will 100% comply to your contracted service requirements maintaining utmost professionalism.',
                    text2: 'Our teams are well-versed in CCTV equipment, Access Control, Keyless Entry, Telephone Entry, Alarm Systems, Automatic Door Operators, Sliding Doors, Barrier Gates, Magnetic Door Locks, Electric Door Strikes, Lock Hardware, Fire Alarm Monitoring Services, and Customized Security Systems.',
                    listItems: null
                },
            ]
        };
    }
}
</script>

<style scoped>
h2 {
    padding-top: 20%;
}
</style>