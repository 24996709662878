<template>
    <div class="col-12 col-md-3 mx-4 shadow-sm rounded p-4 mb-5 bg-dark text-white">
        <p class="lead fw-bold">{{ contactName }}</p>
        <p class="lead" v-if="contactTitle">{{ contactTitle }}</p>
        <p class="lead contactMethod" v-if="contactPhone"><font-awesome-icon icon="fa-solid fa-phone" class="me-2" /><a :href="phone">{{ contactPhone }}</a></p>
        <p class="lead contactMethod"><font-awesome-icon icon="fa-solid fa-envelope" class="me-2" /><a :href="email">{{ contactEmail }}</a></p>
    </div>
</template>

<script>
export default {
    props: ['contactName', 'contactTitle', 'contactEmail', 'contactPhone'],
    computed: {
        phone(){
            return "tel:"+this.contactPhone;
        },
        email(){
            return "mailto:"+this.contactEmail;
        }
    }
}
</script>

<style scoped>
a{
    text-decoration: none;
    color: white;
}

.contactMethod {
    transition: all .2s ease-in;
}

.contactMethod:hover{
    /* font-size: 21px; */
    transform: scale(1.1);
}
</style>