<template>
    <section class="container-fluid pt-2 bg-light text-center">
        <h1 class="my-5">LOCATIONS</h1>
        <div class="row justify-content-center">
            <location-container v-for="location in locations" :key="location.location"
                :location-location="location.location"
                :location-address="location.address"
                :location-map="location.mapLink"
                :location-phone="location.phone"
                :location-email="location.email"
            ></location-container>
        </div>
    </section>
</template>

<script>
import LocationContainer from '../components/location/LocationContainer.vue';
export default {
    components: { LocationContainer },
    data() {
        return {
            locations: [
                {
                    location: 'Head Office in Toronto',
                    address: 'Unit 4-7360, Zinnia Place, Mississauga, ON L5W 2A1',
                    mapLink: 'https://goo.gl/maps/7M5H9E3GvQMPCMRB7',
                    phone: '+1 647-309-8065',
                    email: 'admin@marissamsinc.com',
                },
                {
                    location: 'Head Office in Alberta',
                    address: '1003, Goldfinch way, Edmonton, AB T5S 2B9',
                    mapLink: 'https://goo.gl/maps/i7FQYRhv2bHEpxET8',
                    phone: '+1 905-302-2888',
                    email: 'mms.ab@marissamsinc.com',
                },
            ]
        };
    }
}
</script>