<template>
    <section class="container-fluid jumbotron">
        <div class="col-md-6 content text-white">
            <div class="h-100 p-5 text-white rounded-3 transclucent">
                <h2 class="mb-4">Grow your career with MMS</h2>
                <p class="lead">
                    Our community is growing - and so is our team
                </p>
                <a href="#jobs" class="btn btn-outline-warning">View Jobs</a>
            </div>
        </div>
    </section>
</template>

<style scoped>
.jumbotron {
    height: 100vh;
    background-image: url("../../assets/career2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 5%;
}

/* .content {
    padding-top: 15%;
} */

.transclucent{
    background-color: rgba(0, 0, 0, 0.4);
}

@media (max-width: 768px){
    /* .content {
        padding-top: 45%;
    } */

    .jumbotron {
        background-image: url("../../assets/career2-sm.jpg");
        padding-top: 10%;
    }
}
</style>