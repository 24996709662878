import "bootstrap/dist/css/bootstrap.css";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPeopleGroup, faHandshake, faMagnifyingGlass, faUserCheck, faThumbsUp, faPhone, faEnvelope, faBars, faAnglesUp, faBuilding, faLocationDot, } from '@fortawesome/free-solid-svg-icons';
import { createApp } from 'vue';
import { MotionPlugin } from "@vueuse/motion";
import App from './App.vue';
import router from './router';

library.add(faPeopleGroup, faHandshake, faMagnifyingGlass, faUserCheck, faThumbsUp, faPhone, faEnvelope, faBars, faAnglesUp, faBuilding, faLocationDot,);

const app = createApp(App);

app.use(router);
app.use(MotionPlugin);

app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');

import "bootstrap/dist/js/bootstrap.js";