<template>
    <section class="container-fluid">
        <h2 class="pt-3 text-center">CLIENT TESTIMONIALS</h2>
        <div class="container">
            <testimonial-card v-for="testimonial in testimonials" :key="testimonial.person" 
            :testimonial-person="testimonial.person"
            :testimonial-text="testimonial.text"
            :testimonial-image="testimonial.image"
            ></testimonial-card>
        </div>
    </section>
</template>

<script>
import TestimonialCard from './TestimonialCard.vue';
export default {
    components: { TestimonialCard },
    data(){
        return {
            testimonials: [
                {
                    person: 'A Resident of Village Terrace',
                    text: '"He is professional, efficient, smart, decent, polite and very helpful gentleman over last few years at the Village Terraces Condos, with no hiccups. He worked feverishly and brought the property developed significantly. Thank you, Lalith, for \'Aiming High.\' We always appreciate your hard work"',
                },
                {
                    person: 'Fred',
                    text: '"I have worked with many individuals over the years, but surprisingly Lality was exceptional. He was not only hardworking but also walked that extra mile to deliver what was required. He was the most sort after indivual to attend to the day to day requirements. MMS is professoinal in approach and has becomme a household name."',
                },
                {
                    person: 'Oak and Co, Condo',
                    text: '"We are very pleased with the service MMS provided to us so far. Clear communications and high level of service attendancec. Glad to be partnered with MMS"'
                },
                {
                    person: 'Lakeshore Condo',
                    text: '"Great workmanship demonstrated by MMS within our Condo service requirements. In a very challenging and demanding work environment, MMS is providing us much needed support. All the best to Anil and his team."',
                },
                {
                    person: 'Capital Condos',
                    text: '"If you are looking for complete Condo Management services, MMS is the place to be connected with. Excellent service offered by them with their 24/7 support model."'
                }
            ]
        };
    }
}
</script>

<style scoped>
section {
    background: #000000;
    margin-top: 20px;
}

.container {
    max-height: 450px;
    padding: 30px;
    display: flex;
    overflow-x: scroll;
}

h2{
    color: #DF3124;
}

::-webkit-scrollbar {
    height: 0.8em;
}

::-webkit-scrollbar-thumb {
    background: #DF3124;
    border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
    /* background: #7B0001; */
    background: #C00001;
}

@supports (scrollbar-color: red blue ) {
    * {
        scrollbar-color: #C00001 #DF3124;
        scrollbar-width: thin;
    }
}
</style>