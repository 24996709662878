<template>
    <div>
        <about-jumbotron></about-jumbotron>
        <section class="container my-5">
            <div class="row justify-content-center">
                <quotes-card v-for="quote in quotes" :key="quote.title"
                    :quote-title="quote.title"
                    :quote-text="quote.text"
                ></quotes-card>
            </div>
            <div class="text-center">
                <h3>"We are proudly serving more than 1250 Canadian Residents."</h3>
                <div class="mt-4 row" v-motion-slide-visible-bottom>
                    <div class="col-md-4 offset-md-2">
                        <h1 class="display-1">10,692</h1>
                        <p class="">Residents</p>
                    </div>
                    <div class="col-md-4">
                        <h1 class="display-1">2673</h1>
                        <p class="">Units</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="container-fluid bg-light my-5">
            <div class="text-center container  pt-5">
                <h1 class="mb-4">OUR STRENGTH</h1>
                <p class="fs-5">We are delivering our services to your properties. Our experienced staff will provide a
                    committed and quality service and we will monitor and ensure through our supervisors
                    that our standards are maintained by delivering the highest level of service excellence.
                    <br><br>
                    The MMS will take measures to ensure that your properties are maintained in a clean and
                    presentable condition in accordance with the standards of our company. We conduct a 
                    quality survey enabling us to evaluate our own services periodically. This will assist
                    us to track quality and measure our performance to deliver an excellent service going
                    forward and to maintain our standards. Our aim is to always provide an excellent 
                    service for the payment you make.
                </p>
            </div>
            <h1 class="text-center pt-5">WHY WORK WITH US</h1>
            <div class="row justify-content-center text-center">
                <services-container v-for="tile in tiles" :key="tile.title" 
                    :service-title="tile.title"
                    :service-text="tile.text"
                ></services-container>
        </div>
        </section>
        <!-- <section>
            <emphasized-tile v-for="tile in tiles" :key="tile.title"
                :tile-title="tile.title"
                :tile-text="tile.text"
                :tile-class="tile.class"
            ></emphasized-tile>
        </section> -->
    </div>
</template>

<script>
import AboutJumbotron from '../components/about/AboutJumbotron.vue';
import QuotesCard from '../components/about/QuotesCard.vue';
import ServicesContainer from '../components/services/ServicesContainer.vue';
// import EmphasizedTile from '../components/about/EmphasizedTile.vue';
export default {
    components: { AboutJumbotron, QuotesCard, ServicesContainer },
    data(){
        return {
            quotes: [
                {
                    title: 'OUR VISION',
                    text: 'We support our stakeholders throughout the Property Life Cycle with innovative, service oriented, and experienced staff with the right attitude'
                },
                {
                    title: 'OUR MISSION',
                    text: 'To be the most sort after complete building solutions service provider in Ontario'
                }
            ],
            tiles: [
                // {
                //     title: 'WHY WORK WITH US',
                //     text: null,
                //     class: 'red'
                // },
                // {
                //     title: 'Our Strength',
                //     text: `We are delivering our services to your propeties. Our experienced staff will provide a
                //     committed and quality service and we will monitor and ensure through our supervisors
                //     that our standards are maintained by delivering highest level of service excellence.
                //     \n\n
                //     The MMS will take measures to ensure that your properties are maintained in a clean and
                //     presentable condition in accordance with the standards of our company. We conduct a 
                //     quality survey enabling us to evaluate our own services periodically. This will assist
                //     us to track quality and measure our performance to deliver an excellent service going
                //     forward and to maintain our standards. Our aim is to always provide an excellent 
                //     service for the payment you make.`,
                //     class: 'strength'
                // },
                {
                    title: 'INDUSTRY EXPERIENCE',
                    text: `Small is beautiful. We are a small and medium scale corporation who provides a personalized
                    service to our values partners.`,
                    class: 'industry'
                },
                {
                    title: 'TRUSTED TEAM',
                    text:  `Our team is geared to win the hearts of residents and valued partners.`,
                    class: 'team'
                },
                {
                    title: 'ACCURACY AND PROFESSIONAL',
                    text: `At MMS we build relationships with the personal touch and work smart to gain the confidence.`,
                    class: 'accuracy'
                },
                {
                    title: '24/7/365 ON CALL SERVICE',
                    text:  `Unmatched service excellence on 24/7/365. We are just a call away while you rest at home
                    or out of premises any time of the day for any emergency.`,
                    class: 'phone'
                },
                {
                    title: 'VALUE FOR MONEY',
                    text: `We deliver unmatched service with honesty to satisfy our valued stakeholders. Your
                    satisfaction is our wealth.`,
                    class: 'value'
                }
            ]
        };
    }
}
</script>