<template>
    <div>
        <section class="container-fluid jumbotron">
            <div class="col-md-6 content">
                <div class="h-100 p-5 text-white bg-dark rounded-3">
                    <h2 class="mb-4">“Your Property is in Good Hands”</h2>
                    <p>
                        We will provide you Quality Service to fit your requirements, and our services are open 24/7
                        all year. Our full time, part time and seasonal experienced
                        and trusted staff members are well regarded in the industry. Our focus is to conduct the
                        business with honesty, dedication, and integrity to build up new relationships. We
                        continuously seek avenues to provide an excellent service that bring value to the
                        Properties/Residents/Tenants.
                    </p>
                    <p>
                        We have a team specifically assigned to Property Management work, ensuring the best
                        value for your landlord, and a satisfied tenant. We provide hassle-free, same-day service.
                        No job is too big or small for our skilled and presentable tradesmen. We carry full liability
                        insurance value 5 million dollars and our employees are covered by WSIB.
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>

<style scoped>
.jumbotron {
    height: 105vh;
    background-image: url("../../assets/buildingmaintenance.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 5%;
}

li{
    list-style: none;
    padding: 10px 0;
}

@media (max-width: 768px){
    .jumbotron {
        height: 120vh;
        padding-top: 10%;
    }
}
</style>