<template>
    <section class="container-fluid jumbotron">
        <div class="col-md-6 content">
            <div class="h-100 p-4 text-white bg-dark rounded-3">
                <!-- <h1 class="mb-4">Marissa Management &amp; Services Inc.</h1> -->
                <p>
                    Marissa Management &amp; Services Inc. (MMS) is an Ontario and Alberta registered group of 
                    companies providing complete building solutions to its customers. MMS is keen and delighted to
                    provide a quality service to our Clients and Partners while maintaining the customer satisfaction
                    and industry standards.
                </p>
                <p class="mb-0">
                    Services we offer:
                </p>
                <ul>
                    <li><span>General Contracting</span></li>
                    <li><span>Building Superintendent Services</span></li>
                    <li><span>Disaster Restoration</span></li>
                    <li><span>HVAC / Plumbing / Electrical Services</span></li>
                    <li><span>Property Maintenance / Janitorial Services</span></li>
                    <li><span>Property Rentals / Tenant Screening</span></li>
                    <li><span>Security Management</span></li>
                </ul>
                <p class="text--red">
                    MMS provides its services to Residential, Commercial and Industrial buildings which are fitted
                    with highly Sophisticated Equipment, Building Automation, and Artificial Intelligence based
                    systems. With a team of experts boosting extensive experience in the field, we are well placed
                    to help our clients grow and thrive - even in challenging times.
                </p>
                <p class="text--red">
                    We are privileged to currently serve the tallest and largest residential vertical community
                    tower in Canada.
                </p>
                <router-link to="/aboutUs" class=" mt-4 btn btn-outline-secondary">Find Out More</router-link>
            </div>
        </div>
    </section>
</template>

<style scoped>
.jumbotron {
    height: 130vh;
    background-image: url("../../assets/toronto3.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 5%;
}

li{
    color: #DF3124;
}

li span{
    color: white;
}

.text--red {
    color: #DF3124;
}

@media (max-width: 768px){
    .jumbotron {
        padding-top: 10%;
    }
}
</style>