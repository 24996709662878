<template>
    <div class="col-md-4 shadow-sm">
        <div class="my-5">
        <div class="icon">
            <font-awesome-icon :icon="keyIcon" />
        </div>
        <div>
            <h2>{{ keyTitle }}</h2>
        </div>
        <div>
            <p>{{ keyDescription }}</p>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['keyTitle', 'keyDescription', 'keyIcon']
}
</script>

<style scoped>
.icon{
    background: #DF3124;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    font-size: 50px;
    text-align: center;
    float: left;
}

h2 {
    padding-left: 110px;
}

p {
    padding-left: 110px;
}
</style>