<template>
    <div>
        <career-jumbotron></career-jumbotron>
        <section class="container-fluid">
            <div class="lead text-center my-5">
                <p>
                    We are always looking for Professionals with passion to join our team for below positions.
                </p>
                <p>
                    Those who got matching profiles, may submit their CVs + Reference letter to email
                    admin@marissamsinc.com
                </p>
            </div>
        </section>
        <section class="container-fluid my-5" id="jobs">
            <h2 class="text-center mb-5">Current job openings</h2>
            <job-container v-for="job in jobs" :key="job.title"
                :job-title="job.title"
                :job-location="job.location"
                :job-responsibilities="job.responsibilities"
                :job-requirements="job.requirements"
                :job-attributes="job.attributes"
                :working-conditions="job.workingConditions"
                :job-permanent="job.permanent"
            ></job-container>
        </section>
    </div>
</template>

<script>
import CareerJumbotron from '../components/career/CareerJumbotron.vue';
import JobContainer from '../components/career/JobContainer.vue';
export default {
    components: { CareerJumbotron, JobContainer },
    data(){
        return {
            jobs: [
                {
                    title: 'Building Superintendant (Living in/Living out)',
                    location: 'Toronto, Canada',
                    responsibilities: [
                        'Performing various building maintenance and repairs.',
                        'Respond to after business hours & weekend emergency calls (on a rotational basis).',
                        'Perform general cleaning, landscaping, and snow removal where services are not met or performed by a Contractor.',
                        'Processing, completing work orders, and returning closed work orders.',
                        'Garbage bin removal to proper pick up area two times per week or as directed.',
                        'Administration of spare parts and supplies.',
                        'Plaster, drywall, paint, plumbing, electrical, and carpentry as required.',
                        'Garbage removal, repairs, and cleaning of vacant units.',
                        'On duty every second weekend and every second night.',
                        'Other assignments within the scope, spirit, and purpose of the job as reasonably requested from time to time by management.'
                    ],
                    requirements: [
                        'High school education at minimum. Additional educational or trades courses are an asset.',
                        'Minimum 3-5 years building maintenance experience.',
                        'Demonstrated basic carpentry, electrical, and plumbing skills.',
                        'Knowledge and expertise with hand and power tools.',
                        'Ability to work outdoors, lift up to 25 kilos, and work at heights on ladders.',
                        'Maintain confidentiality, tact, and diplomacy as well as a positive attitude and effective interpersonal skills.',
                        'Ability to work independently and as a member of a team.',
                        'Have a valid G driver\'s license.',
                        'Basic computer literacy is a must (MS Word, Excel).'
                    ],
                    attributes: null,
                    workingConditions: null,
                    permanent: true
                },
                {
                    title: 'Building Cleaner',
                    location: 'Toronto, Canada',
                    responsibilities: [
                        'Cleaning equipment surfaces, fixtures, desks/tables (boardrooms) and kitchen surfaces.',
                        'Sweeping, mopping and vacuuming all floors.',
                        'Refill supplies for building occupants to use (example: toilet paper, paper towels, soap, cleaning supplies etc.).',
                        'Washing interior windows, walls and ceilings.',
                        'Dusting of entire location.',
                        'Thorough cleaning and sanitization of the washrooms including mirrors, sinks, fixtures and all handles.',
                        'Emptying and removal of all garbage, debris and recycling from the building.',
                        'Maintaining cleanliness of the cafeteria and kitchens.',
                        'Load the compactor, waste/recycling bins and wood bin.',
                        'Other duties as assigned.'
                    ],
                    requirements: [
                        'High School Diploma.',
                        'WHMIS and MSDC certifications considered an asset.',
                        'Minimum of 2 years of experience cleaning offices, manufacturing or warehouse facilities.'
                    ],
                    attributes: [
                        'Ability to lift 50 lbs, bend over, crouch and use ladders.',
                        'Self-motivated, able to work well independently.',
                        'Ability to stand and walk for long period of time.'
                    ],
                    workingConditions: [
                        'Manufacturing environment.'
                    ],
                    permanent: false
                },
                {
                    title: 'Disaster Restoration Team Member',
                    location: 'Toronto, Canada',
                    responsibilities: [
                        'Inspection to determine affected areas.',
                        'Take pictures of affected work areas',
                        'Removal of standing water from affected areas.',
                        'Removal of debris and affected building materials as required (baseboard, drywall, insulation, flooring, etc.).',
                        'Installation of drying equipment.',
                        'Return visits to monitor the drying process and removal of equipment.',
                        'Cleaning of surfaces not being removed (lightly smoke damaged/non-porous material)',
                        'Cleaning/basic maintenance of equipment (extractors, air movers, dehumidifiers, air scrubbers, etc.).',
                        'Proper completion of paperwork (work orders, drying logs, etc.)'
                    ],
                    requirements: [
                        'IICRC Certification preferred.',
                        'Have a valid G driver\'s license.'
                    ],
                    attributes: [
                        'Ability to lift 50 lbs.',
                        'Keep work areas and vehicles clean/tidy.',
                    ],
                    workingConditions: null,
                    permanent: false
                },
                {
                    title: 'Electrician',
                    location: 'Toronto, Canada',
                    responsibilities: [
                        'Oversee required inspections of electrical systems to ensure compliance with Ontario Electrical Standards Act.',
                        'Read and interpret drawings, blueprints, schematics and electrical code specifications to determine layout of industrial electrical equipment installations.',
                        'Install, examine, replace or repair electrical wiring, receptacles, switch boxes, conduits, feeders, lighting fixtures and other electrical components as needed.',
                        'Test electrical and electronic equipment and components for continuity, current, voltage and resistance.',
                        'Update and log electrical changes.',
                        'Execute minor Electrical repairs.',
                        'Maintain needed spares and purchase material in the most cost- effective manner.',
                        'Coordinate with all plant services ad contractors as required.',
                        'Perform other duties/projects as assigned.',
                        'Daily report using a computerized system.'
                    ],
                    requirements: [
                        'Technical school training and has completed at least the Apprenticeship Program. Experienced is still preferred.',
                        'Licensed Electrician (309A and/or442A).',
                        'Working knowledge of Allen Bradley/ Siemens PLC\'s using SLC 500 and Control Logics.',
                        'Ability to take information from pre-maintenance work orders to determine the location and the kind of work to be done.',
                        'Read schematic drawings in order to troubleshoot and repair equipment.',
                        'Ability to troubleshoot, diagnose, debug & repair industrial electrical equipment, electrical controls.',
                        'Maintain and install any electrical work required to plants and office buildings.',
                        'Ability to interact with production staff to co-ordinate repairs to their equipment and discuss work orders with Supervisors.',
                        'Ability to take measurements using high voltage testing equipment. Use formulas from the Electrical Code to determine sizes of cables needed when sizes of motors and the lengths of cable runs are known.',
                        'Ability to work independently, however, work also as part of a team that includes other trades people and professionals to install, repair and maintain electrical systems and equipment.'
                    ],
                    attributes: null,
                    workingConditions: null,
                    permanent: false
                },
                {
                    title: 'Plumber',
                    location: 'Toronto, Canada',
                    responsibilities: [
                        'Troubleshoot, service, and maintain heating systems and boilers.',
                        'Maintain and repair circulating pump.',
                        'Identify and address plumbing problems in high-rise apartment buildings and commercial properties.',
                        'Clear blocked drains of various sizes using drain machines.',
                        'Install, maintain, and service plumbing fixtures (tub & showers, taps, toilets, etc.).',
                        'Troubleshoot and resolve problems with potable water and/or drain piping in high-rise buildings and commercial properties.',
                        'Troubleshoot and resolve problems with circulating and sump pumps in high-rise building and commercial properties.',
                        'Be on 24-hour on-call emergency service for one full week every few weeks.',
                        'Prepare written quotes and service requisitions for office.',
                        'Adhere to safety policies and procedures.'
                    ],
                    requirements: [
                        '1 year plumbing experience',
                        '306A Plumbing License : Up to date.',
                        'Class G Ontario Driver\'s License.',
                        'Good written and verbal communication skills',
                        'Experience with maintenance, troubleshooting, and service of heating systems and boilers - Not required, but would be a plus.',
                    ]
                }
            ]
        };
    }
}
</script>