<template>
    <header>
        <router-link to="/" >
            <div class="brand">
                <img src="../../assets/MMS_Logo_new.jpg" alt="logo" width="110" height="90">
                <h2>Marissa Management &amp; Services Inc.</h2>
            </div>
        </router-link>
        <nav>
            <ul class="nav__links" v-if="!mobileView">
                <li v-for="link in links" :key="link.label">
                    <router-link :to="link.link" >{{ link.label }}</router-link>
                </li>
            </ul>
            <font-awesome-icon icon="fa-solid fa-bars" id="icon" @click="toggleNavigation" v-else />
        </nav>
    </header>
    <mobile-navigation v-if="showNav" @close-menu="closeMenu" v-motion-slide-top
        :nav-links="links"
    ></mobile-navigation>
</template>

<script>
import MobileNavigation from './MobileNavigation.vue';
export default {
    props: ['mobileView'],
    components: { MobileNavigation },
    data(){
        return {
            showNav: false,
            links: [
                {
                    label: 'Home',
                    link: '/'
                },
                {
                    label: 'Who we are',
                    link: '/aboutUs'
                },
                {
                    label: 'Services',
                    link: '/services'
                },
                {
                    label: 'Our Clients',
                    link: '/clients'
                },
                {
                    label: 'Career',
                    link: '/career'
                },
                {
                    label: 'Locations',
                    link: '/locations'
                },
                {
                    label: 'Contact Us',
                    link: '/contactUs'
                }
            ]
        };
    },
    methods: {
        toggleNavigation(){
            this.showNav = !this.showNav;
        },
        closeMenu(){
            this.showNav = false;
        }
    },
    watch: {
        mobileView(value){
            if(!value){
                this.closeMenu();
            }
        }
    }
}
</script>

<style scoped>
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: white;
}

h2 {
    font-size: 1.75rem;
}

li, a, h2 {
    color: #DF3124;
    text-decoration: none;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 2.5%;
}

.brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__links {
    list-style: none;
}

.nav__links li {
    display: inline-block;
    padding: 0 10px;
}

.nav__links li a {
    transition: all 0.3s ease 0s;
}

.nav__links li a:hover,
.router-link-exact-active {
    color: black;
}

#icon {
    font-size: 2rem;
    cursor: pointer;
}

@media(max-width: 768px) {
    h2 {
        font-size: 1.55rem;
    }
}
</style>