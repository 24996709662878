<template>
    <div class="col-12 col-md-4 bg-white shadow-sm text-center mx-4 mb-5">
        <h4>{{ quoteTitle }}</h4>
        <p class="lead">"{{ quoteText }}"</p>
    </div>
</template>

<script>
export default {
    props: ['quoteTitle', 'quoteText']
}
</script>

<style scoped>
h4 {
    color: #DF3124;
}
</style>