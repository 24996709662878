<template>
    <div class=" col-12 col-md-4 bg-white mx-5 px-4 py-3 rounded">
        <div class="justify-content-center mb-4">
            <h6>{{ testimonialPerson }}</h6>
        </div>    
        <p>{{ testimonialText }}</p>
    </div>
</template>

<script>
export default {
    props: ['testimonialPerson', 'testimonialText', 'testimonialImage']
}
</script>

<style scoped>
img {
    height: 4.5rem;
    border-radius: 50%;
    margin-right: 1rem;
}
</style>
