<template>
    <div class="col-12 col-md-5 bg-dark text-white shadow-sm rounded p-4 m-4" v-motion-slide-visible-bottom>
        <h3 class="mb-4">{{ serviceTitle }}</h3>
        <p>{{ serviceText }}</p>
        <p v-if="serviceText2">{{ serviceText2 }}</p>
        <ol v-if="listItems">
            <li v-for="item in listItems" :key="item" >{{ item }}</li>
        </ol>
    </div>
</template>

<script>
export default {
    props: ['serviceTitle', 'serviceText', 'serviceText2', 'listItems']
}
</script>