<template>
    <div>
        <home-jumbotron></home-jumbotron>
        <key-values></key-values>
        <client-testimonials></client-testimonials>
    </div>
</template>

<script>
import HomeJumbotron from '../components/home/HomeJumbotron.vue';
import KeyValues from '../components/home/KeyValues.vue';
import ClientTestimonials from '../components/home/ClientTestimonials.vue';
export default {
    components: { HomeJumbotron, KeyValues, ClientTestimonials }
}
</script>