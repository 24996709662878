import { createRouter, createWebHistory } from "vue-router";

import HomePage from './pages/HomePage.vue';
import AboutPage from './pages/AboutPage.vue';
import ServicesPage from './pages/ServicesPage.vue';
import CareerPage from './pages/CareerPage.vue';
import LocationsPage from './pages/LocationsPage.vue';
import ContactPage from './pages/ContactPage.vue';
import ClientsPage from './pages/ClientsPage.vue';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: HomePage },
        { path: '/aboutUs', component: AboutPage },
        { path: '/services', component: ServicesPage },
        { path: '/career', component: CareerPage },
        { path: '/locations', component: LocationsPage },
        { path: '/contactUs', component: ContactPage },
        { path: '/clients', component: ClientsPage },
        { path: '/privacyPolicy', component: PrivacyPolicyPage },
        { path: '/:notFound(.*)', redirect: '/' }
    ],
    scrollBehavior(to, _, savedPosition){
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { el: to.hash, behavior: "smooth" };
        }
        window.scrollTo(0, 0);
    }
});

export default router;