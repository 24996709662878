<template>
  <scroll-button></scroll-button>
  <the-header :mobile-view="mobileView" ></the-header>
  <router-view v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view>
  <the-footer :mobile-view="mobileView" ></the-footer>
</template>

<script>
import TheHeader from './components/layout/TheHeaderV3.vue';
import TheFooter from './components/layout/TheFooter.vue';
import ScrollButton from './components/layout/ScrollButton.vue';
export default {
  components: { TheHeader, TheFooter, ScrollButton },
  data(){
    return {
      mobileView: false
    };
  },
  methods: {
    handleView(){
      this.mobileView = window.innerWidth <= 990;
    }
  },
  created(){
    this.handleView();
    window.addEventListener('resize', this.handleView);
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.route-enter-from {
    opacity: 0;
    transform: translateY(-30px);
}
.route-enter-active {
    transition: all 0.3s ease-out;
}
.route-enter-to,
.route-leave-from {
    opacity: 1;
    transform: translateY(0);
}
.route-leave-active {
    transition: all 0.3s ease-in;
}
.route-leave-to {
    opacity: 0;
    transform: translateY(30px);
} 
</style>
