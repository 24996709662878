<template>
    <div class="col-12 col-md-3 mx-4 shadow-sm rounded p-4 mb-5 bg-dark text-white">
        <p class="lead fw-bold"><font-awesome-icon icon="fa-solid fa-building" class="me-2" />{{ locationLocation }}</p>
        <p class="lead location-info"><font-awesome-icon icon="fa-solid fa-location-dot" class="me-2" /><a :href="locationMap" target="_blank">{{ locationAddress }}</a></p>
        <p class="lead location-info"><font-awesome-icon icon="fa-solid fa-phone" class="me-2" /><a :href="phone">{{ locationPhone }}</a></p>
        <p class="lead location-info"><font-awesome-icon icon="fa-solid fa-envelope" class="me-2" /><a :href="email">{{ locationEmail }}</a></p>
    </div>
</template>

<script>
export default {
    props: ['locationLocation', 'locationAddress', 'locationMap', 'locationPhone', 'locationEmail'],
    computed: {
        phone(){
            return "tel:"+this.locationPhone;
        },
        email(){
            return "mailto:"+this.locationEmail;
        }
    }
}
</script>

<style scoped>
a{
    text-decoration: none;
    color: white;
}

.location-info {
    font-size: 18px;
    transition: all .2s ease-in;
}

.location-info:hover{
    /* font-size: 21px; */
    transform: scale(1.1);
}
</style>