<template>
    <section class="mt-5  container">
        <header class="mb-5 text-center">
            <h3>Six Key Values of Marissa Management &amp; Services Inc.</h3>
        </header>
        <div class="row justify-content-center rounded" >
            <key-container v-for="key in keys" :key="key.key"
                :key-title="key.key"
                :key-description="key.description"
                :key-icon="key.icon"
            ></key-container>
        </div>
    </section>
</template>

<script>
import KeyContainer from './KeyContainer.vue';
export default {
    components: { KeyContainer },
    data(){
        return {
            keys: [
                {
                    key: 'Diversity',
                    description: 'Taking your hassle away is our investment',
                    icon: 'fa-solid fa-people-group'
                },
                {
                    key: 'Respect',
                    description: 'We walk the talk',
                    icon: 'fa-solid  fa-handshake'
                },
                {
                    key: 'Transparency',
                    description: 'Delivering value for your investment is our philosophy',
                    icon: 'fa-solid fa-magnifying-glass'
                },
                {
                    key: 'Integrity',
                    description: 'Your satisfaction is the measure of our success. Not only business - we build relationships',
                    icon: 'fa-solid fa-user-check'
                },
                {
                    key: 'Trustworthy',
                    description: 'Fundamental honesty is the keystone of success',
                    icon: 'fa-solid  fa-handshake'
                },
                {
                    key: 'Credibility',
                    description: 'Trust is earned when action meets words',
                    icon: 'fa-solid fa-thumbs-up'
                }
            ]
        };
    }
}
</script>